<template>
  <!--begin::Card-->
  <div>
    <div class="form-group">
      <label>Mail From</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="mail_from"
        placeholder="hello@restaurant.com"
      />
      <span class="form-text text-muted"
        >Enter the email address that you would like emails sent from the system
        to appear to come from.</span
      >
    </div>
<div class="form-group">
      <label>Confirmation Email Address</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="confirmation_mail"
      />
      <span class="form-text text-muted">This is the email address to which orders will be sent. This is the email address which will also receive printer failure notifications.</span
      >
    </div>
    <!-- <div class="form-group">
      <label>Mail Customer Subject</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="mail_subject_customer"
        placeholder="Subject for email to customer"
      />
      <span class="form-text text-muted"
        >Enter the subject line to be used on emails sent to customer.</span
      >
    </div> -->
    <!-- <div class="form-group">
      <label>Email Subject</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="mail_subject"
        placeholder="Subject for email"
      />
      <span class="form-text text-muted"
        >Enter the subject line to be used on emails send to you.</span
      >
    </div> -->
    <!-- <div class="form-group">
      <label>Confirmation Email Address</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="confirmation_mail"
        placeholder="Confirmation Email Address"
      />
      <span class="form-text text-muted"
        >This is the email address to which orders will be sent. This is the
        email address which will also receive printer failure
        notifications.</span
      >
    </div> -->
    <div class="form-group">
      <label>SMTP Username</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="smtp_username"
        placeholder="SMPT Username"
      />
      <span class="form-text text-muted"
        >Enter the SMTP username for your email account - your ISP should be
        able to provide this.</span
      >
    </div>
    <div class="form-group">
      <label>SMTP Password</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="smtp_pwd"
        placeholder="SMPT Password"
      />
      <span class="form-text text-muted"
        >Enter the SMTP password for your email account - your ISP should be
        able to provide this.</span
      >
    </div>
    <div class="form-group">
      <label>SMTP Server</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="smtp_server"
        placeholder="SMPT Server"
      />
      <span class="form-text text-muted"
        >Enter the SMTP server for your email account - your ISP should be able
        to provide this.</span
      >
    </div>
    <div class="form-group">
      <label>SMTP Port</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="smtp_port"
        placeholder="SMPT Port"
      />
      <span class="form-text text-muted"
        >Enter the SMTP port for your email account - your ISP should be able to
        provide this.</span
      >
    </div>
    <!-- <div class="form-group">
      <label>SMTP use SSL?</label>
      <b-form-radio v-model="smtp_ssl" value="1"> Yes </b-form-radio>
      <b-form-radio v-model="smtp_ssl" value="0"> No </b-form-radio>
      <span class="form-text text-muted"
        >Select yes if your email account uses SSL.
      </span>
    </div> -->
    <hr />
    <div class="form-group" style="float: right">
      <b-button @click="save">Save</b-button>
    </div>
    <div class="form-group">
      <b-button :disabled="inProgress" @click="sendEmail"
        >Send Test Email</b-button
      > {{ message }}
    </div>
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";
import {
  GENERAL_SETTINGS,
  SEND_TEST_EMAIL
} from "@/core/services/store/config.module";
export default {
  name: "EmailSetting",
  props: ["settings"],
  data() {
    return {
      inProgress: false,
      mail_from: "",
      mail_subject_customer: "",
      mail_subject: "",
      confirmation_mail: "",
      smtp_username: "",
      smtp_pwd: "",
      smtp_server: "",
      smtp_port: "",
      smtp_ssl: 0,
      message : '',
    };
  },
  methods: {
    save() {
      this.$store
        .dispatch(GENERAL_SETTINGS, {
          mail_from: this.mail_from,
          mail_subject_customer: this.mail_subject_customer,
          mail_subject: this.mail_subject,
          confirmation_mail: this.confirmation_mail,
          smtp_username: this.smtp_username,
          smtp_pwd: this.smtp_pwd,
          smtp_server: this.smtp_server,
          smtp_port: this.smtp_port,
          smtp_ssl: this.smtp_ssl
        })
        // go to which page after successfully login
        .then(resp => {
          // this.form.orders = resp.data;
          if (resp.status === "Success") {
            Swal.fire("Information!", resp.message, "success");
            return;
          } else {
            Swal.fire("Error", resp.message, "error");
            return;
          }
          // this.$router.push({ name: "dashboard" })
        })
        .catch(error => {
          if (error.status === "Error") {
            Swal.fire("Error", error.message, "error");
            return;
          }
          console.log(error);
        });
    },
    sendEmail() {
      if (
        this.mail_from != "" &&
        this.smtp_username != "" &&
        this.smtp_server != "" &&
        this.smtp_pwd != "" &&
        this.smtp_port != ""
      ) {
        this.inProgress = true;
        this.$store
          .dispatch(SEND_TEST_EMAIL, {
            mail_from: this.mail_from,
            smtp_username: this.smtp_username,
            smtp_pwd: this.smtp_pwd,
            smtp_server: this.smtp_server,
            smtp_port: this.smtp_port
          })
          .then(resp => {
            this.inProgress = false
            this.message = resp;
          })
          .catch(error => {
            this.message = error.message;
          });
      } else {
        Swal.fire("Error", "All fields must be entered", "error");
        return;
      }
    },
    cancel() {
      //
    }
  },
  mounted() {
    for (let i = 0; i < this.settings.length; i++) {
      if (this.settings[i].key == "mail_from") {
        this.mail_from = this.settings[i].value;
      }
      if (this.settings[i].key == "mail_subject_customer") {
        this.mail_subject_customer = this.settings[i].value;
      }
      if (this.settings[i].key == "mail_subject") {
        this.mail_subject = this.settings[i].value;
      }
      if (this.settings[i].key == "confirmation_mail") {
        this.confirmation_mail = this.settings[i].value;
      }
      if (this.settings[i].key == "smtp_username") {
        this.smtp_username = this.settings[i].value;
      }
      if (this.settings[i].key == "smtp_pwd") {
        this.smtp_pwd = this.settings[i].value;
      }
      if (this.settings[i].key == "smtp_server") {
        this.smtp_server = this.settings[i].value;
      }
      if (this.settings[i].key == "smtp_port") {
        this.smtp_port = this.settings[i].value;
      }
      if (this.settings[i].key == "smtp_ssl") {
        this.smtp_ssl = this.settings[i].value;
      }
    }
  }
};
</script>

<template>
  <!--begin::Card-->
  <div class="col-md-12">
    <div class="form-group">
      <label>POS Integration URL</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="url"
        placeholder="URL"
        disabled
       />
      <span class="form-text text-muted">
          Paste this url in pos gmf settings.
      </span>
    </div>
    <hr />
  </div>

  <!--end::Card-->
</template>

<script>
import { GET_POS_INT_SETTINGS } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
export default {
  name: "PosIntegrationSettings",
  props : ['settings'],
  data() {
    return {
      url: '',
    };
  },
  methods: {

  },
  mounted(){
    this.$store
        .dispatch(GET_POS_INT_SETTINGS)
        // go to which page after successfully login
        .then((resp) => {
        this.url = resp.data.url;
        this.load = true;
        // this.$router.push({ name: "dashboard" })
        })
        .catch((error) => {
        if (error.status === "Error") {
            Swal.fire("Error", error.message, "error");
            return;
        }
        console.log(error);
        });
  }
};
</script>

<template>
  <!--begin::Card-->
  <div class="col-md-12">
    <div class="form-group">
      <label>Contact Us Page Email</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="contact_us_email"
        placeholder="Contact us Email"
      />
      <span class="form-text text-muted">Enter Contact us Page Email</span>
    </div>
    <div class="form-group">
      <label>Google map API key</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="gmap_api_key"
        placeholder="API key"
      />
      <span class="form-text text-muted"
        >Enter your Google maps API key, this can be found in your Google Maps
        or Google APIs control panel.</span
      >
    </div>
    <div class="form-group">
      <label>Timezone</label>
      <b-form-select v-model="timezone" :options="timezones"></b-form-select>
      <span class="form-text text-muted"
        >Choose your timezone from the list below.</span
      >
    </div>
    <div class="form-group">
      <label>CURRENCY</label>
      <b-form-select v-model="currency" :options="currencies"></b-form-select>
      <span class="form-text text-muted"
        >Enter the symbol used for your chosen currency eg. £, € etc.</span
      >
    </div>
    <div class="form-group">
      <label>Future Date Order</label>
      <b-form-radio v-model="future_date_order" value="1"> Yes </b-form-radio>
      <b-form-radio v-model="future_date_order" value="0"> No </b-form-radio>
      <span class="form-text text-muted"
        >Allowing customers to place order in future dates.
      </span>
    </div>
    <div class="form-group">
      <label>Sticky Meny</label>
      <b-form-radio v-model="sticky_menu" value="1"> Yes </b-form-radio>
      <b-form-radio v-model="sticky_menu" value="0"> No </b-form-radio>
      <span class="form-text text-muted"
        >If you would like the right-side part of the menu/order page to remain
        always visible even when a user scrolls down the page select Yes below.
      </span>
    </div>
    <div class="form-group">
      <label>Blocked List</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="blocked_list"
        placeholder="Blocked Ips"
      />
      <span class="form-text text-muted"
        >Enter a list of IP or email addresses which you would like to be
        blocked, seperated by ";". eg.
        192.168.1.1;helo@yahoo.com;102.34.12.43;</span
      >
    </div>
    <div class="form-group">
      <label>Enable Captcha</label>
      <b-form-radio v-model="enable_captcha" value="1"> Yes </b-form-radio>
      <b-form-radio v-model="enable_captcha" value="0"> No </b-form-radio>
      <span class="form-text text-muted"
        >Select yes if you would like to check captcha at the checkout.
      </span>
    </div>
    <div class="form-group">
      <label>Enable Url Rewrite</label>
      <b-form-radio v-model="url_rewrite" value="1"> Yes </b-form-radio>
      <b-form-radio v-model="url_rewrite" value="0"> No </b-form-radio>
      <span class="form-text text-muted"
        >Select yes if you would like to enable Url Rewrite.
      </span>
    </div>
    <div class="form-group">
      <label>Number of Orders</label>
      <b-form-checkbox-group
        v-model="number_of_orders"
        :options="options"
      ></b-form-checkbox-group>
    </div>
    <div class="form-group">
      <label>Components Color</label>
      <input
        type="color"
        class="form-control form-control-solid form-control-lg"
        v-model="colors"
        placeholder="Select Color"
      />
    </div>
    <hr />
    <div class="form-group" style="float: right">
      <b-button class="bg-primary text-light font-poppins"  @click="save">Save</b-button>
    </div>
  </div>

  <!--end::Card-->
</template>

<script>
import { GENERAL_SETTINGS } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
export default {
  name: "GeneralSetting",
  props : ['settings'],
  data() {
    return {
      colors : '',
      gmap_api_key: "",
      timezone: null,
      currency: null,
      google_tracking: 0,
      google_tracking_code: "",
      sticky_menu: 0,
      blocked_list: "",
      enable_captcha: 0,
      contact_us_email : "",
      future_date_order : 0,
      url_rewrite: 0,
      number_of_orders: [],
      options: [
        { text: "Printer receipts", value: "print_receipts" },
        {
          text: "Thank-you for your order page and emails",
          value: "thankyou_emails",
        },
        { text: "Sales Dashboards", value: "sales_dashboards" },
      ],
      timezones: [{ text: "America/Detroit", value: "America/Detroit" },{ text: "America/Indiana/Marengo", value: "America/Indiana/Marengo" },{ text: "America/Indiana/Vincennes", value: "America/Indiana/Vincennes" },{ text: "America/New_York", value: "America/New_York" },{ text: "America/Los_Angeles", value: "America/Los_Angeles" },{ text: "America/Indiana/Indianapolis", value: "America/Indiana/Indianapolis" },{ text: "America/Detroit", value: "America/Detroit" },{ text: "America/Denver", value: "America/Denver" },{ text: "America/Chicago", value: "America/Chicago" },{ text: "America/Boise", value: "America/Boise" },{ text: "America/Anchorage", value: "America/Anchorage" },{ text: "America/Adak", value: "America/Adak" },{ text: "Europe/London", value: "Europe/London" },{ text: "Asia/Dubai", value: "Asia/Dubai" },{ text: "Asia/Karachi", value: "Asia/Karachi" },{ text: "Asia/Kolkata", value: "Asia/Kolkata" },{ text: "Europe/Vienna", value: "Europe/Vienna" } , { text: "Australia/Sydney", value: "Australia/Sydney" },{ text: "Australia/Melbourne", value: "Australia/Melbourne" },{ text: "Europe/Brussels", value: "Europe/Brussels" },{ text: "Asia/Kabul", value: "Asia/Kabul" },{ text: "Europe/Dublin", value: "Europe/Dublin" }],
      currencies: [
    { text: "$", value: "$" },
    { text: "€", value: "€" },
    { text: "£", value: "£" },
    { text: "₵", value: "GHS" }, // Ghanaian Cedi
    { text: "A$", value: "AUD" }, // Australian Dollar
    { text: "C$", value: "CAD" }  // Canadian Dollar
]
    };
  },
  methods: {
    save() {
      this.$store
        .dispatch(GENERAL_SETTINGS, {
          google_map_key: this.gmap_api_key,
          timezone: this.timezone,
          currency: this.currency,
          google_tracking: this.google_tracking,
          google_tracking_code: this.google_tracking_code,
          sticky_menu: this.sticky_menu,
          blocked_list: this.blocked_list,
          contact_us_email: this.contact_us_email,
          enable_captcha: this.enable_captcha,
          url_rewrite: this.url_rewrite,
          number_of_orders: this.number_of_orders,
          future_date_order: this.future_date_order,
          color : this.colors,
        })
        // go to which page after successfully login
        .then((resp) => {
          // this.form.orders = resp.data;
          if (resp.status === "Success") {
            Swal.fire("Information!", resp.message, "success");
            return;
          } else {
            Swal.fire("Error", resp.message, "error");
            return;
          }
          // this.$router.push({ name: "dashboard" })
        })
        .catch((error) => {
          if (error.status === "Error") {
            Swal.fire("Error", error.message, "error");
            return;
          }
          console.log(error);
        });
    },
    cancel() {
      //
    },

  },
  mounted()
  {
    for(let i = 0 ; i < this.settings.length; i ++)
    {
      if(this.settings[i].key == "google_map_key")
      {
        this.gmap_api_key = this.settings[i].value;
      }
      if(this.settings[i].key == "timezone")
      {
        this.timezone = this.settings[i].value;
      }
      if(this.settings[i].key == "currency")
      {
        this.currency = this.settings[i].value;
      }
      if(this.settings[i].key == "google_tracking")
      {
        this.google_tracking = this.settings[i].value;
      }
      if(this.settings[i].key == "google_tracking_code")
      {
        this.google_tracking_code = this.settings[i].value;
      }
      if(this.settings[i].key == "sticky_menu")
      {
        this.sticky_menu = this.settings[i].value;
      }
      if(this.settings[i].key == "blocked_list")
      {
        this.blocked_list = this.settings[i].value;
      }
      if(this.settings[i].key == "enable_captcha")
      {
        this.enable_captcha = this.settings[i].value;
      }
      if(this.settings[i].key == "url_rewrite")
      {
        this.url_rewrite = this.settings[i].value;
      }
      if(this.settings[i].key == "contact_us_email")
      {
        this.contact_us_email = this.settings[i].value;
      }
      if(this.settings[i].key == "future_date_order")
      {
        this.future_date_order = this.settings[i].value;
      }
      if(this.settings[i].key == "color")
      {
        this.colors = this.settings[i].value;
      }
      if(this.settings[i].key == "number_of_orders")
      {
        if (this.settings[i].value)
        {
          this.number_of_orders = JSON.parse(this.settings[i].value);
        }
      }
    }
  }
};
</script>

<template>
  <!--begin::Card-->
  <div>
    <div class="form-group">
      <label>Minimum amount for card payment</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="min_payment"
        placeholder="Min Payment"
      />
      <span class="form-text text-muted"
        >Input the minimum order amount to accept card payment.</span
      >
    </div>
    <div class="form-group">
      <label>Enable cash payments</label>
      <b-form-radio v-model="cash_payment" value="1"> Yes </b-form-radio>
      <b-form-radio v-model="cash_payment" value="0"> No </b-form-radio>
      <span class="form-text text-muted"
        >If you would like to allow cash payment.
      </span>
    </div>
    <div class="form-group">
      <label>Service Charge</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="service_charge"
        placeholder="Service Charge"
      />
      <span class="form-text text-muted">Set a service charge on orders.</span>
    </div>
    <div class="form-group">
      <b-form-checkbox
        id="checkbox-1"
        v-model="service_charge_only_instore"
        value="1"
        unchecked-value="0"
      >
        Tick this box is Service Charges apply to In-Store ordering ONLY.
      </b-form-checkbox>
    </div>
    <div class="form-group">
      <label>Credit Card Surcharge</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="credit_card_surcharge"
        placeholder="Surcharge on Credit Card"
      />
      <span class="form-text text-muted"
        >If you charge an additional fee for credit cards please enter it
        below.</span
      >
    </div>
    <div class="form-group">
      <label>Xonder</label>
      <b-form-radio v-model="xonder" value="1"> Yes </b-form-radio>
      <b-form-radio v-model="xonder" value="0"> No </b-form-radio>
      <span class="form-text text-muted"
        >Select Yes if you would like to offer Cardstream as a payment option at
        the checkout.
      </span>
    </div>
    <div class="form-group">
      <label>XONDER Merchant ID</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="payment_xonder_merchant_id"
        placeholder="Xonder Merchant ID"
      />
      <span class="form-text text-muted"
        >Enter your XONDER merchant id, this can be found in your cardstream
        control panel.</span
      >
    </div>
    <div class="form-group">
      <label>XONDER Signature</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="payment_xonder_secret"
        placeholder="Xonder Signature"
      />
      <span class="form-text text-muted"
        >Enter your XONDER Signature, this can be found in your cardstream
        control panel.</span
      >
    </div>
    <hr />
    <div class="form-group" style="float: right">
      <b-button @click="save">Save</b-button>
    </div>
  </div>
  <!--end::Card-->
</template>

<script>
import Swal from "sweetalert2";
import { GENERAL_SETTINGS } from "@/core/services/store/config.module";

export default {
  name: "PaymentSetting",
  props : ['settings'],
  data() {
    return {
      min_payment: "0",
      cash_payment: 0,
      service_charge: "0",
      service_charge_only_instore: 0,
      credit_card_surcharge: "0",
      xonder: 0,
      payment_xonder_merchant_id: "",
      payment_xonder_secret: "",
    };
  },
  methods: {
    save() {
      this.$store
        .dispatch(GENERAL_SETTINGS, {
          min_payment: this.min_payment,
          cash_payment: this.cash_payment,
          service_charge: this.service_charge,
          service_charge_only_instore: this.service_charge_only_instore,
          credit_card_surcharge: this.credit_card_surcharge,
          xonder: this.xonder,
          payment_xonder_merchant_id: this.payment_xonder_merchant_id,
          payment_xonder_secret: this.payment_xonder_secret,
        })
        // go to which page after successfully login
        .then((resp) => {
          // this.form.orders = resp.data;
          if (resp.status === "Success") {
            Swal.fire("Information!", resp.message, "success");
            return;
          } else {
            Swal.fire("Error", resp.message, "error");
            return;
          }
          // this.$router.push({ name: "dashboard" })
        })
        .catch((error) => {
          if (error.status === "Error") {
            Swal.fire("Error", error.message, "error");
            return;
          }
          console.log(error);
        });
    },
  },
  mounted()
  {
    for(let i = 0 ; i < this.settings.length; i ++)
    {
      if(this.settings[i].key == "min_payment")
      {
        this.min_payment = this.settings[i].value;
      }
      if(this.settings[i].key == "cash_payment")
      {
        this.cash_payment = this.settings[i].value;
      }
      if(this.settings[i].key == "service_charge")
      {
        this.service_charge = this.settings[i].value;
      }
      if(this.settings[i].key == "service_charge_only_instore")
      {
        this.service_charge_only_instore = this.settings[i].value;
      }
      if(this.settings[i].key == "credit_card_surcharge")
      {
        this.credit_card_surcharge = this.settings[i].value;
      }
      if(this.settings[i].key == "xonder")
      {
        this.xonder = this.settings[i].value;
      }
      if(this.settings[i].key == "payment_xonder_merchant_id")
      {
        this.payment_xonder_merchant_id = this.settings[i].value;
      }
      if(this.settings[i].key == "payment_xonder_secret")
      {
        this.payment_xonder_secret = this.settings[i].value;
      }
    }
  }
};
</script>

<template>
  <!--begin::Card-->
  <div class="col-md-12">
    <div class="form-group">
      <label>Allow Reservation</label>
      <b-form-radio v-model="reservation" value="1"> Yes </b-form-radio>
      <b-form-radio v-model="reservation" value="0"> No </b-form-radio>
      <span class="form-text text-muted"
        >Select yes if you would like to enable reservation for customers.
      </span>
    </div>
    <div class="form-group">
      <label>Number of Guests Allowed</label>
      <input
        type="text"
        class="form-control form-control-solid form-control-lg"
        v-model="guests_allowed"
        placeholder="Number of Guests Allowed"
       />
      <span class="form-text text-muted">
          Enter the number of guests allowed in business
      </span>
    </div>
    <hr />
    <div class="form-group" style="float: right">
      <b-button @click="save">Save</b-button>
    </div>
  </div>

  <!--end::Card-->
</template>

<script>
import { GENERAL_SETTINGS } from "@/core/services/store/config.module";
import Swal from "sweetalert2";
export default {
  name: "ReservationSettings",
  props : ['settings'],
  data() {
    return {
      reservation: 0,
      guests_allowed: 0,
    };
  },
  methods: {
    save() {
      this.$store
        .dispatch(GENERAL_SETTINGS, {
          reservation: this.reservation,
          guests_allowed: this.guests_allowed,
        })
        // go to which page after successfully login
        .then((resp) => {
          // this.form.orders = resp.data;
          if (resp.status === "Success") {
            Swal.fire("Information!", resp.message, "success");
            return;
          } else {
            Swal.fire("Error", resp.message, "error");
            return;
          }
          // this.$router.push({ name: "dashboard" })
        })
        .catch((error) => {
          if (error.status === "Error") {
            Swal.fire("Error", error.message, "error");
            return;
          }
          console.log(error);
        });
    },
    cancel() {
      //
    },

  },
  mounted()
  {
    for(let i = 0 ; i < this.settings.length; i ++)
    {
      if(this.settings[i].key == "reservation")
      {
        this.reservation = this.settings[i].value;
      }
      if(this.settings[i].key == "guests_allowed")
      {
        this.guests_allowed = this.settings[i].value;
      }
    }
  }
};
</script>

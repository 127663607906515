<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <div class="row my-10 px-8 my-lg-15 px-lg-21">
        <div class="col-md-12">
          <b-tabs content-class="mt-3">
            <b-tab title="General Settings" active>
              <GeneralSetting :settings="settings" v-if="load"></GeneralSetting>
            </b-tab>
            <b-tab title="Payment Settings">
              <PaymentSetting :settings="settings" v-if="load"></PaymentSetting>
            </b-tab>
            <b-tab title="Restaurant Info">
              <RestuarantInfo :settings="settings" v-if="load"></RestuarantInfo>
            </b-tab>
            <b-tab title="Email Settings">
              <EmailSetting :settings="settings" v-if="load"></EmailSetting>
            </b-tab>
            <!-- <b-tab title="Template Settings">
              <TemplateSettings :settings="settings" v-if="load"></TemplateSettings>
            </b-tab> -->
            <b-tab title="Reservation Settings">
              <ReservationSettings :settings="settings" v-if="load"></ReservationSettings>
            </b-tab>
            <b-tab title="POS Integration">
              <PosIntegrationSettings :settings="settings" v-if="load" />
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import GeneralSetting from "@/view/pages/business/components/settings/GeneralSetting";
import PaymentSetting from "@/view/pages/business/components/settings/PaymentSetting";
import EmailSetting from "@/view/pages/business/components/settings/EmailSetting";
import TemplateSettings from "@/view/pages/business/components/settings/TemplateSettings";
import ReservationSettings from "@/view/pages/business/components/settings/ReservationSettings";
import PosIntegrationSettings from "@/view/pages/business/components/settings/PosIntegrationSettings";
import RestuarantInfo from "@/view/pages/business/components/settings/RestuarantInfo";
import { GET_GENERAL_SETTINGS } from "@/core/services/store/config.module";

export default {
  name: "Setting",
  components: {
    GeneralSetting,
    PaymentSetting,
    EmailSetting,
    TemplateSettings,
    ReservationSettings,
    RestuarantInfo,
    PosIntegrationSettings
  },
  data() {
    return {
      settings : [],
      load  : false
    };
  },

  mounted() {

    this.getGeneralSettings()


    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Business Settings" },
      { title: "Settings" }
    ]);
  },
  methods: {
    submit: function(e) {
      e.preventDefault();
      Swal.fire({
        title: "",
        text: "The application has been successfully submitted!",
        icon: "success",
        confirmButtonClass: "btn btn-primary"
      });
    },
    getGeneralSettings()
    {
      this.$store
          .dispatch(GET_GENERAL_SETTINGS)
          // go to which page after successfully login
          .then((resp) => {
            this.settings = resp;
            this.load = true;
            // this.$router.push({ name: "dashboard" })
          })
          .catch((error) => {
            if (error.status === "Error") {
              Swal.fire("Error", error.message, "error");
              return;
            }
            console.log(error);
          });
    }
  },
};
</script>
